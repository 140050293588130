var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{attrs:{"api-url":_vm.APIURL,"delete-content":true,"block-content":false,"edit-content":true,"view-content":false,"selectable":false,"columns":_vm.filteredColumns,"type":_vm.type,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"cell(wallet)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(data.item.payment_method === 'wallet')?_c('span',[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"25","color":"blue"}})],1):_c('span')])]},null,data)]}},{key:"cell(offer)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(data.item.offer)?_c('span',[_vm._v(" "+_vm._s(data.item.offer.name)+" - "+_vm._s(data.item.offer.amount)+" % ")]):_vm._e()])]},null,data)]}},{key:"cell(facility)",fn:function(data){return [_vm._t("actions",function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EyeIcon"},on:{"click":function($event){return _vm.$router.push({
                name: 'request-histories',
                params: { id: data.item.id }
              })}}})],1)]},null,data)]}},{key:"cell(assign_engineer)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('b-btn',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap","background-color":"#0564f2!important","border":"1px solid #0564f2!important","margin":"5px"},on:{"click":function($event){return _vm.$router.push({
                name: 'assign-engineer',
                params: { id: data.item.id }
              })}}},[_vm._v(" Assign Engineer ")])],1)]},null,data)]}},{key:"cell(edit_payment_status)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('b-btn',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap","background-color":"#0564f2!important","border":"1px solid #0564f2!important","margin":"5px"},on:{"click":function($event){return _vm.$router.push({
                name: 'edit-payment-status',
                params: { id: data.item.id }
              })}}},[_vm._v(" Edit Payment Status ")])],1)]},null,data)]}},{key:"cell(set_offer)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[_c('b-btn',{staticStyle:{"font-size":"smaller","text-wrap":"nowrap","background-color":"#0564f2!important","border":"1px solid #0564f2!important","margin":"5px"},on:{"click":function($event){return _vm.$router.push({
                name: 'set-offer',
                params: { id: data.item.id }
              })}}},[_vm._v(" Set Offer ")])],1)]},null,data)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
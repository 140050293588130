<template>
  <div>
    <general-table
      :api-url="APIURL"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :selectable="false"
      :columns="filteredColumns"
      :type="type"
      :per-page="perPage"
    >
      <template #cell(wallet)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.payment_method === 'wallet'">
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="blue"
              />
            </span>

            <span v-else />
          </div>
        </slot>
      </template>
      <template #cell(offer)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.offer">
              {{ data.item.offer.name }} - {{ data.item.offer.amount }} %
            </span>
          </div>
        </slot>
      </template>
      <template #cell(facility)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <div style="text-align: center;">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'request-histories',
                  params: { id: data.item.id }
                })
              "
            />
          </div>
        </slot>
      </template>
      <template #cell(assign_engineer)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <b-btn
              style="font-size: smaller; text-wrap: nowrap; background-color: #0564f2!important; border: 1px solid #0564f2!important;margin: 5px;"
              @click="
                $router.push({
                  name: 'assign-engineer',
                  params: { id: data.item.id }
                })
              "
            >
              Assign Engineer
            </b-btn>
          </div>
        </slot>
      </template>
      <template #cell(edit_payment_status)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <b-btn
              style="font-size: smaller; text-wrap: nowrap; background-color: #0564f2!important; border: 1px solid #0564f2!important;margin: 5px;"
              @click="
                $router.push({
                  name: 'edit-payment-status',
                  params: { id: data.item.id }
                })
              "
            >
              Edit Payment Status
            </b-btn>
          </div>
        </slot>
      </template>
      <template #cell(set_offer)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <b-btn
              style="font-size: smaller; text-wrap: nowrap; background-color: #0564f2!important; border: 1px solid #0564f2!important; margin: 5px;"
              @click="
                $router.push({
                  name: 'set-offer',
                  params: { id: data.item.id }
                })
              "
            >
              Set Offer
            </b-btn>
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
// import { title } from '@/@core/utils/filter'
import { mapState } from 'vuex'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'requests/over_due',
      viewContent: true,
      type: 'page',
      perPage: 50,
      filter: {},
      columns: [
        { key: 'id', sortable: false },
        { key: 'request_code', label: 'Request Code' },
        { key: 'payment_status', label: 'Payment Status' },
        { key: 'payment_method', label: 'Payment Method' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'source', label: 'Source' },
        { key: 'certificate', label: 'Certificate' },
        { key: 'office.name', label: 'Office' },
        { key: 'offer', label: 'Offer' },
        { key: 'certificate_price', label: 'Certificate Price' },
        { key: 'tax', label: 'Tax' },
        { key: 'other_tax', label: 'Other Tax' },
        { key: 'final_price', label: 'Final Price' },
        { key: 'wallet', label: 'Wallet' },
        { key: 'facility', label: 'Show Request Infomation' },
        { key: 'assign_engineer', label: 'Assign Engineer' },
        { key: 'edit_payment_status', label: 'Edit Payment Status' },
        { key: 'set_offer', label: 'Set Offer' },
      ],
    }
  },
  computed: {
    ...mapState({
      role: state => state.login.role,
    }),
    filteredColumns() {
      if (this.role === 'super-accountant' || this.role === 'accountant') {
        return this.columns.filter(column => [
          'id',
          'request_code',
          'payment_status',
          'payment_method',
          'due_date',
          'source',
          'certificate',
          'office.name',
          'offer',
          'final_price',
          'wallet',
          'facility',
          'edit_payment_status',
        ].includes(column.key))
      }
      if (this.role === 'process-manager') {
        return this.columns.filter(column => [
          'id',
          'request_code',
          'payment_status',
          'payment_method',
          'due_date',
          'source',
          'certificate',
          'office.name',
          'offer',
          'final_price',
          'wallet',
          'facility',
          'assign_engineer',
        ].includes(column.key))
      }
      if (this.role === 'markter') {
        return this.columns.filter(column => [
          'id',
          'request_code',
          'payment_status',
          'payment_method',
          'due_date',
          'source',
          'certificate',
          'office.name',
          'offer',
          'final_price',
          'wallet',
          'facility',
          'set_offer',
        ].includes(column.key))
      }
      return this.columns
    },
    apiUrl() {
      const base = 'requests/over_due?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },
  methods: {
    filterTypes(type) {
      this.types = this.status.filter(item => item.text !== type)
    },
  },
}
</script>

<style></style>
